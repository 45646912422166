/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from "react";
import { useLocation } from "@reach/router";
import { parse } from "query-string";

import Layout from "../components/Layout";
import PageWrapper from "../components/PageWrapper";
import ContentWrapper from "../components/ContentWrapper";
import SideWrapper from "../components/SideWrapper";
import Sidenavs from "../components/Sidenavs";
import SponsorSlider from "../components/SponsorSlider";
import Chase from "../components/Chase";

const Page = () => {
  const props = useLocation().search;
  const userData = props ? parse(props) : null;
  const redirect =
    typeof window !== "undefined" ? localStorage?.getItem("redirect") : null;

  useEffect(() => {
    if (typeof window !== "undefined" && !!userData) {
      localStorage.setItem("user", JSON.stringify(userData));
    }

    setTimeout(() => {
      window.location.assign(!!redirect ? redirect : "/");
    }, 1000);
  });

  return (
    <Layout hideFooter>
      <PageWrapper>
        <ContentWrapper>
          <Chase />
        </ContentWrapper>
      </PageWrapper>
    </Layout>
  );
};

export default Page;
