import React from "react";
import tw from "twin.macro";
import styled, { keyframes, css } from "styled-components";

const skChase = keyframes`
  100% { transform: rotate(360deg); }
`;

const skChaseDot = keyframes`
  80%, 100% { transform: rotate(360deg); } 
`;

const skChaseDotBefore = keyframes`
  50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  } 
`;

const Spinner = styled.div(({ numDots }) => [
  tw`absolute w-12 h-12`,
  css`
    .sk-chase-dot {
      ${Array(numDots)
        .fill(null)
        .map(
          (_, i) =>
            `:nth-child(${i + 1}) { 
              animation-delay: ${-1.1 + 0.1 * i}s; 
            } 
            :nth-child(${i + 1}):before { 
              animation-delay: ${-1.1 + 0.1 * i}s;
              opacity: ${1 - i / 20}
            }`
        )}
    }
    animation: ${skChase} 2.5s infinite linear both;
  `,
]);

const Dot = styled.div(({ color }) => [
  tw`h-full w-full absolute left-0 top-0 before:(content[''] block w-1/4 h-1/4 bg-secondary rounded-full)`,
  css`
    animation: ${skChaseDot} 2s infinite ease-in-out both;

    &:before {
      animation: ${skChaseDotBefore} 2s infinite ease-in-out both;
    }
  `,
  !!color &&
    css`
      &:before {
        background-color: ${color};
      }
    `,
]);

const Chase = ({ color, numDots = 6, ...rest }) => {
  return (
    <Spinner className="sk-chase" numDots={numDots} {...rest}>
      {Array(numDots)
        .fill(null)
        .map((_, i) => (
          <Dot key={i} color={color} className="sk-chase-dot" />
        ))}
    </Spinner>
  );
};

export default Chase;
